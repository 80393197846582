import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import Home from './components/home/Home';
import SignOut from './components/authentication/SignOut';
import Footer from './components/Footer';
import Header from './components/Header';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div className="app">
      <Header />
      <div className="content mb-4">
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/sign-out" component={SignOut} />
            <Route exact path="*" render={() => <Redirect to='/'/>}/>
          </Switch>
        </BrowserRouter>
      </div>
      <Footer />
    </div>
  );
}

export default App;
