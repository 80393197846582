import React, { useState } from "react";
import Auth from "@aws-amplify/auth";
import config from "../../config";
import styles from "./CustomSignIn.module.css";
import * as Sentry from '@sentry/browser';

function CustomSignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [serverError, setServerError] = useState();
  const [isLoggingIn, setIsLoggingIn] = useState();

  const handleSubmit = async () => {
    setIsLoggingIn(true);
    setServerError(false);

    if (!email || !email.trim()) {
      setIsLoggingIn(false);
      return setServerError("Email cannot be empty");
    }

    if (!password || !password.trim()) {
      setIsLoggingIn(false);
      return setServerError("Password cannot be empty");
    }

    try {
      localStorage.clear();
      await Auth.signIn(email.toLowerCase().trim(), password);
      window.location.href = "";
    } catch (err) {
      setIsLoggingIn(false);
      if (err && (err.code === "UserNotFoundException" || err.code === 'InvalidParameterException' || !err.code)) {
        setServerError("Incorrect email or password.");
      } else if (err && err.message) {
        const errorMessage = err.message.replace(/username/gi, "email");
        setServerError(errorMessage);

        if (errorMessage === "QuotaExceededError") {
          Sentry.captureException(err);
        }
      } else {
        setServerError("Incorrect email or password.");
      }
    }
  };

  const handleEmailChange = e => {
    setEmail(e.currentTarget.value);
  };

  const handlePasswordChange = e => {
    setPassword(e.currentTarget.value);
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className={styles.section}>
      <div className={styles.formContainer}>
        <div className={styles.formSection}>
          <div className={styles.sectionHeader}>
            <span className={styles.sectionHeaderContent}>Welcome Back!</span>
          </div>

          <div className={styles.sectionBody}>
            <div className={styles.formField}>
              <div className={styles.inputLabel}>Email *</div>
              <input
                placeholder="Enter your email"
                name="email"
                className={styles.input}
                value={email}
                onChange={handleEmailChange}
                data-testid="email"
              />
            </div>
            <div className={styles.formField}>
              <div className={styles.inputLabel}>Password *</div>
              <input
                placeholder="Enter your password"
                type="password"
                name="password"
                className={styles.input}
                value={password}
                onChange={handlePasswordChange}
                onKeyDown={handleKeyDown}
                data-testid="password"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <a
                className="font-weight-normal small float-right mb-3"
                href={`${config.coreAppUrl}users/password/new`}
              >
                {" "}
                Forgot your password?
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                className=" btn btn-primary btn-block py-2"
                onClick={handleSubmit}
                disabled={isLoggingIn}
                data-testid="signInBtn"
              >
                {isLoggingIn ? "Logging in..." : "Log In"}
              </button>
            </div>
          </div>

          {serverError && (
            <div className="row align-items-center">
              <div className="col small text-danger text-center pt-3" data-testid="errorMessage">
                {serverError}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomSignIn;
