import React, { useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';

import CustomSignIn from '../authentication/CustomSignIn';
import LoopSpinner from '../util/LoopSpinner';

import styles from './Home.module.css';

import config from '../../config';

Amplify.configure({
  Auth: {
    region: config.awsRegion,
    userPoolId: config.awsUserPoolId,
    userPoolWebClientId: config.awsUserPoolWebClientId,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
});

function Home () {
  const [loading, setLoading] = useState(false);

  Auth.currentSession().then(session => {
    setLoading(true);
    window.location.href = `${config.coreAppUrl}cognito-confirm-sign-in?id_token=${session.idToken.jwtToken}&${window.location.search.replace('?', '')}`
  }).catch(_e => {});

  return (
    <div>
      { loading ?
          <div className={styles.spinner}>
            <LoopSpinner size={120} />
            <div className="text-secondary font-weight-light mt-2">Loading your account...</div>
          </div> :
          <div>
            <CustomSignIn />
          </div>
      }
    </div>
  );
}

export default Home;
