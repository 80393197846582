// appStage: development | production
const dev = {
  coreAppUrl: 'https://jarvis.lendingloop.ca/',
  env: 'development',
  awsRegion: 'ca-central-1',
  awsUserPoolId: 'ca-central-1_9rZcSyhhH',
  awsUserPoolWebClientId: 'dne0l87slb5jgptpo2bh8d28s',
  sentryDsn: 'https://4dcf0c58ff1249bab42eee34e1e04a55@sentry.io/1888999'
};

const config = {
  coreAppUrl: process.env.REACT_APP_CORE_APP_URL || dev.coreAppUrl,
  env: process.env.REACT_APP_ENV || dev.env,
  awsRegion: process.env.REACT_APP_AWS_REGION || dev.awsRegion,
  awsUserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || dev.awsUserPoolId,
  awsUserPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || dev.awsUserPoolWebClientId,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN || dev.sentryDsn
};

export default config;
