import React from 'react';

import styles from './Header.module.css';

function Header () {
  return (
    <div className={styles.header}>
      <div className="d-flex justify-content-center">
        <a className="navbar-brand" href="/">
          <img height="30px" alt="Lending Loop" src="./logo.svg" />
        </a>
      </div>
    </div>
  );
}

export default Header;
