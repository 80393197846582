import React from 'react';
import { FaFacebookF, FaTwitter } from 'react-icons/fa';

function Footer () {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer bg-white py-2">
      <div className="container d-flex justify-content-between align-items-center">
        <ul className="list-inline list-group-flush list-group-borderless mb-0">
          <li className="list-inline-item pl-0">
            <span className="small text-muted mb-0">© Lending Loop { currentYear } </span>
          </li>
          <li className="list-inline-item">
            <a className="small" target="_blank" rel="noopener noreferrer" href="https://support.lendingloop.ca/">Help</a>
          </li>
          <li className="list-inline-item">
            <a className="small" target="_blank" rel="noopener noreferrer" href="https://www.lendingloop.ca/terms-of-use">Terms of Service</a>
          </li>
          <li className="list-inline-item">
            <a className="small" target="_blank" rel="noopener noreferrer" href="https://www.lendingloop.ca/privacy-policy">Privacy Policy</a>
          </li>
        </ul>

        <ul className="list-inline mb-0">
          <li className="list-inline-item">
            <a className="btn btn-icon" rel="noopener noreferrer" href="https://www.facebook.com/lendingloop/" target="_blank">
              <FaFacebookF />
            </a>
          </li>
          <li className="list-inline-item">
            <a className="btn btn-icon" rel="noopener noreferrer" href="https://twitter.com/lendingloop" target="_blank">
              <FaTwitter />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
