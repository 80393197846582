import React from 'react';

const LoopIcon = props => {
  const { size = '32px', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 640 640"
      preserveAspectRatio="xMidYMid meet"
      {...rest}
    >
      <g
        transform="translate(0.000000,639.000000) scale(0.100000,-0.100000)"
        fill="#492660"
        stroke="none"
      >
        <path d="M3005 6383 c-252 -20 -421 -47 -619 -98 -702 -181 -1324 -603 -1768 -1202 -688 -925 -810 -2197 -312 -3251 366 -777 1050 -1387 1862 -1661 844 -285 1802 -200 2567 227 273 152 488 315 715 542 321 321 545 659 710 1070 279 699 301 1496 59 2212 -361 1069 -1287 1885 -2385 2102 -283 55 -597 78 -829 59z m500 -943 l320 -320 -837 -837 c-648 -648 -842 -848 -857 -883 -25 -55 -27 -139 -5 -183 9 -18 211 -228 450 -467 l434 -435 -330 -330 -330 -330 -755 755 c-824 824 -804 801 -792 901 3 27 13 63 22 81 15 28 2341 2368 2355 2368 3 0 149 -144 325 -320z m1827 -1837 c144 -145 269 -275 277 -290 9 -16 15 -54 16 -88 0 -48 -6 -69 -27 -105 -15 -26 -517 -535 -1187 -1205 l-1161 -1160 -322 322 -323 323 837 837 c648 648 842 848 857 883 26 58 27 132 1 184 -13 27 -170 191 -450 471 l-430 430 327 327 328 328 498 -497 c273 -274 615 -616 759 -760z" />
      </g>
    </svg>
  );
};

export default LoopIcon;
