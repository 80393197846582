import React from 'react';
import { Auth } from 'aws-amplify';

function SignOut() {
  Auth.signOut()
    .then((_data) => {
      window.location.href = '/'
    })
  return (<div />);
}

export default SignOut;
