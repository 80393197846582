import React from 'react';

import styles from './LoopSpinner.module.css';
import LoopIcon from './LoopIcon';

const LoopSpinner = props => {
  const { ...rest } = props;

  return <LoopIcon className={styles.logoSpin} {...rest} />;
};

export default LoopSpinner;
